@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Noto+Serif+JP:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Noto+Serif+JP:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Desc-Img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}

.Desc-Contents {
  position: absolute;
  top: 0;
  left: 0;
}

.Desc-Contents-Explain {
  height: 100%;
  background-color: white;
  opacity: 0.7;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
}

.Desc-Contents-Text {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Products-Image {
  width: 100%;
  text-align: center;
}

.Footer-Img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}

.Footer-Back {
  width: 100%;
  height: 40vh;
  background-color: #000000;
  opacity: 0.3;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.Footer-Text {
  width: 100vw;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.App {
  color: #333333;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-family: 'Noto Sans JP', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Appbar-Image {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-Bar-Icon {
  text-align: center;
  width: 10px;
  height: 10px;
}

.Wrap-Space-Text {
  white-space: pre-wrap;
}

.Primary-Yachinaka-Button {
  width: 100%;
  height: 50px;
  background: #f10207 0% 0% no-repeat padding-box;
  border-radius: 30px;
  color: white;
}

