.Desc-Img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}

.Desc-Contents {
  position: absolute;
  top: 0;
  left: 0;
}

.Desc-Contents-Explain {
  height: 100%;
  background-color: white;
  opacity: 0.7;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
}

.Desc-Contents-Text {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Products-Image {
  width: 100%;
  text-align: center;
}

.Footer-Img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}

.Footer-Back {
  width: 100%;
  height: 40vh;
  background-color: #000000;
  opacity: 0.3;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.Footer-Text {
  width: 100vw;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
